import React from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Api } from '../../Api/api';
import { MainPageNewNav } from '../NewNav';
import { PlatformApp, useNavigate } from '@platform/cj-platform-navigation';
import '@cjdev-internal/design-tokens';

// design tokens are css variables that VSX depends on
export type AppDependencies = {
  api: Api;
  doc: Document;
};

export const App = (dependencies: AppDependencies) => {
  const history = useHistory();

  const layout = {
    filters: true,
    sidenav: true,
    dimensions: true,
    pageTitle: true,
    Buttons: true,
  };

  const routes = [
    {
      layout,
      path: '/',
      titleMessage: {
        id: 'nav-home',
        defaultMessage: 'Home',
      },
      Content: () => {
        const navigate = useNavigate();
        navigate('/list');
        return <></>;
      },
    },
    {
      layout,
      path: '/list',
      titleMessage: {
        id: 'nav-root',
        defaultMessage: 'Tag Management',
      },
      Content: ({ user }: any) => (
        <MainPageNewNav
          api={dependencies.api}
          history={history}
          doc={dependencies.doc}
          user={user}
        />
      ),
    },
  ];

  const translations = {};
  return (
    <Router>
      <PlatformApp routes={routes} translations={translations} />
    </Router>
  );
};
